.navElement{
    color: white;
}

.navElement::after{
    content:"";
    display: block;
    justify-content: center;
    width: 100%;
    height: 3px;
    background: #42a5f5;
    transition: transform 0.3s ease-in-out;
    transform: scale(0);
    border-radius: 20%;

    
}
.navElement:hover::after{
    transform: scale(1);
}


.typewriter{
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing:1px; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }

  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black; }
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

/* can add fonts for website here */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');


@layer base{
    body{
        @apply font-[Raleway];
        overflow-x: hidden;
        background-color: #212121;
    }
    

}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #212121;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #777; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }